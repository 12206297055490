<template>
  <v-sheet>
    <v-expansion-panels v-model="selectedPanel" multiple tile flat accordion>
      <v-expansion-panel
        v-for="(menuItem, i) in menuItems"
        :key="i"
        class="border-bottom template-details"
      >
        <v-expansion-panel-header class="py-1">
          <v-list-item class="pl-0">
            <v-list-item-icon class="px-0 mr-2">
              <v-icon v-text="menuItem.icon" />
            </v-list-item-icon>

            <v-list-item-content class="pl-0 ml-0">
              <v-list-item-title class="px-0 text-subtitle-2">
                <Popover
                  v-if="menuItem.title === 'Estruturas'"
                  center
                  title="Estruturas"
                >
                  <template #activator>
                    {{ menuItem.title }}
                    <v-chip color="success" x-small> Novo </v-chip>
                  </template>
                  <v-card tile flat>
                    <v-card-text class="pa-0">
                      Crie assinaturas informativas facilmente com estruturas
                      prontas e personalizáveis para acelerar sua criação.
                    </v-card-text>
                    <v-card-actions class="px-0 mt-2">
                      <v-img
                        contain
                        width="100%"
                        :src="structureExample"
                        alt="Exemplo de estrutura de assinatura"
                      />
                    </v-card-actions>
                  </v-card>
                </Popover>
                <span v-else>{{ menuItem.title }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-expansion-panel-header>

        <v-expansion-panel-content
          v-if="menuItem.title == 'Cores principais'"
          class="border-bottom"
        >
          <!-- Cores -->
          <v-card flat color="transparent">
            <v-list dense class="px-0">
              <v-list-item class="align-center justify-start px-0">
                <v-list-item-content>
                  <v-list-item-title> Redes sociais </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-menu :close-on-content-click="false" location="end">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :color="colorSelected"
                        v-bind="attrs"
                        v-on="on"
                      ></v-btn>
                    </template>
                    <v-card>
                      <v-color-picker
                        class="ma-2"
                        show-swatches
                        swatches-max-height="300px"
                        v-model="colorSelected"
                      >
                      </v-color-picker>
                    </v-card>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
              <v-list-item class="align-center justify-start px-0">
                <v-list-item-content>
                  <v-list-item-title> Cor de fundo </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-menu :close-on-content-click="false" location="end">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :color="colorSelected2"
                        v-bind="attrs"
                        v-on="on"
                      ></v-btn>
                    </template>
                    <v-card>
                      <v-color-picker
                        class="ma-2"
                        show-swatches
                        swatches-max-height="300px"
                        v-model="colorSelected2"
                      >
                      </v-color-picker>
                    </v-card>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-expansion-panel-content>

        <!-- ESTRUTURA DO HTML -->
        <v-expansion-panel-content
          v-if="menuItem.title === 'Estruturas'"
          class="px-0 mx-0"
        >
          <SignatureStructures
            :showStartTip="showStartModelTip"
            @set-structure="selectStructure"
          />
        </v-expansion-panel-content>

        <!-- ICONES -->
        <v-expansion-panel-content
          v-if="menuItem.title == 'Ícones'"
          class="pb-8"
        >
          <SelectColor
            label="Selecionar cor de fundo"
            @setNewColor="setColor"
          />

          <Icons :color="iconColor" @action="setIcon" />
        </v-expansion-panel-content>

        <!-- RODAPÉ -->
        <v-expansion-panel-content
          v-if="menuItem.title == 'Call to Action'"
          class="pb-8"
        >
          <v-list dense nav class="px-0">
            <v-list-item
              :disabled="button.disabled"
              @click="clickedOnFooterButton(button.key)"
              link
              v-for="(button, i) in callToAction"
              :key="i"
              color="lightSign"
            >
              <v-list-item-icon class="mr-3">
                <v-icon
                  :disabled="button.disabled"
                  v-text="button.icon"
                  size="18"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ button.title }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="button.is_new">
                <v-chip x-small color="success"> Novo </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>

        <!-- INFORMAÇÕES DO USUÁRIO -->
        <v-expansion-panel-content
          v-if="menuItem.title == 'Informações do usuário'"
        >
          <v-autocomplete
            placeholder="Pesquisar"
            :items="userTagsList"
            outlined
            item-text="name"
            item-value="tag"
            dense
          />
        </v-expansion-panel-content>

        <!-- INFORMAÇÔES DA EMPRESA -->
        <v-expansion-panel-content
          v-if="menuItem.title == 'Informações da empresa'"
        >
          <v-autocomplete
            placeholder="Pesquisar"
            :items="orgTagsList"
            outlined
            item-text="name"
            item-value="tag"
            dense
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog v-model="openBannerConfig" width="500">
      <v-card class="pb-4">
        <v-card-title>Configurar banner</v-card-title>

        <v-card-subtitle class="mt-2"
          >Configure uma imagem para divulgar produtos e serviços da sua
          empresa. Recomendamos que a imagem utilizada seja carregada no Google
          Drive e compartilhada como pública.
          <a href="" title="Ainda não tem link aqui hehe" class="accent--text"
            >Saiba mais dicas sobre imagens na assiantura</a
          >.
        </v-card-subtitle>

        <v-tabs dark color="accent" vertical>
          <v-tab href="" class="text-subtitle-2">
            <v-icon>mdi-phone</v-icon>
          </v-tab>

          <v-tab-item value="">
            <v-card flat tile>
              <v-card-text>Oi</v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>

        <v-card-text>
          <v-text-field
            type="url"
            v-model="image"
            label="URL da imagem"
            color="accent"
            oulined
            hide-details
          />
        </v-card-text>

        <v-card-text>
          <v-text-field
            type="url"
            v-model="link"
            label="Link do banner"
            color="accent"
            oulined
            hide-details
          />
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            :disabled="!image"
            color="accent"
            class="text-none text-body-1 font-weight-medium px-6 white--text"
            @click="sendBanner"
            elevation="0"
          >
            Inserir banner
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Meeting Dialog -->
    <v-dialog v-model="openMeetingConfig" width="500">
      <v-card class="pb-4">
        <v-card-title>Configurar link da reunião</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="meetingLink"
            type="url"
            label="Link da reunião"
            color="accent"
            oulined
            hide-details
          />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="accent"
            class="text-none text-body-1 font-weight-medium px-6 white--text"
            @click="sendMeeting"
            :disabled="!meetingLink"
            elevation="0"
          >
            Inserir reunião
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Calendar Dialog -->
    <v-dialog v-model="openCalendarConfig" width="500">
      <v-card class="pb-4">
        <v-card-title>Configurar link de agendamento</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="schedulingLink"
            type="url"
            label="Link para agendamento"
            color="accent"
            oulined
            hide-details
          />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="accent"
            class="text-none text-body-1 font-weight-medium px-6 white--text"
            @click="sendScheduling"
            :disabled="!schedulingLink"
            elevation="0"
          >
            Inserir link de agendamento
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AddCustomButtonDialog
      :show="showButtonDialog"
      @add-button="$emit('add-button', $event)"
      @close="showButtonDialog = false"
    />
  </v-sheet>
</template>

<script>
import AddCustomButtonDialog from "@/components/sign/signature/custom_editor_options/AddCustomButtonDialog";
import SignatureStructures from "@/components/sign/signature/SignatureStructures";
import { changeDriveURL } from "@/helpers/services/images";
import { userTagsList, orgTagsList } from "@/helpers/variables/tags";
import { footersButtons } from "@/helpers/variables/templates";
import Icons from "@/components/sign/Icons";
import SelectColor from "@/components/sign/SelectColor";
import { insertLinkInTagImage } from "@/helpers/services/tagPreview";

export default {
  name: "TemplateEditorDrawer",

  props: {
    defaultTemplate: { type: Boolean, default: false },
    emptyHTMLTemplate: { type: Boolean, default: false },
  },

  components: {
    Icons,
    SelectColor,
    SignatureStructures,
    AddCustomButtonDialog,
  },

  data() {
    return {
      userTagsList,
      orgTagsList,
      selectedPanel: this.emptyHTMLTemplate ? [0] : [0, 1, 2],
      footersButtons,
      showButtonDialog: false,
      menuItems: [
        /*{ title: "Cores principais", icon: "mdi-brush-variant" },
        Exibir somente os itens que estão funcionando corretamente
        https://conectanuvem.atlassian.net/browse/CN-5949*/
        { title: "Estruturas", icon: "mdi-bulletin-board", show: true },
        { title: "Ícones", icon: "mdi-vector-square-edit", show: true },
        {
          title: "Call to Action",
          icon: "mdi-shape-square-rounded-plus",
          show: true,
        },
        { title: "Informações do usuário", icon: "mdi-account", show: false },
        { title: "Informações da empresa", icon: "mdi-domain", show: false },
      ].filter((menuItem) => menuItem.show),
      callToAction: [
        {
          title: "Botão",
          icon: "mdi-shape-square-plus",
          key: "button",
          is_new: true,
        },
        {
          title: "Agenda",
          icon: "mdi-calendar",
          key: "calendar",
          is_new: true,
        },
        {
          title: "Reunião online",
          icon: "mdi-monitor-account",
          key: "meeting",
        },
        { title: "Website", icon: "mdi-web", key: "visit_website" },
        {
          title: "Banner",
          icon: "mdi-card-text-outline",
          key: "banner",
          disabled: true,
        },
      ],
      showStartModelTip: false,
      openBannerConfig: false,
      openMeetingConfig: false,
      openCalendarConfig: false,
      schedulingLink: "[*CALENDAR_SCHEDULES_LINK*]",
      meetingLink: "",
      link: "",
      image: "",
      iconColor: "#000000",
      structureExample: require("@/assets/images/Exemplo_de_estrutura.png"),
      timeoutId: 0,
    };
  },

  computed: {
    banner() {
      if (this.link) {
        return `<a href="${this.link}"><img src="${changeDriveURL(
          this.image
        )}" width="580"/></a>`;
      } else {
        return `<img src="${changeDriveURL(this.image)}" width="580"/>`;
      }
    },
    meet() {
      return footersButtons["meeting"].replace("url_do_meet", this.meetingLink);
    },
    calendar() {
      return footersButtons["calendar"].replace(
        "scheduling_link",
        this.schedulingLink
      );
    },
  },

  watch: {
    link() {
      if (this.openBannerConfig || this.openMeetingConfig) {
        this.meetingLink = "";
        this.link = "";
        this.image = "";
      }
    },
  },

  methods: {
    setIcon(icon) {
      const iconImg = `<img src="${icon.url}" alt="${icon.alt}" style="background-color: ${this.iconColor}; padding:2px"  width="22px" height:22px;/>`;
      this.$emit("icon", insertLinkInTagImage(iconImg, icon.alt));
    },

    setColor(color) {
      this.iconColor = color;
    },

    sendBanner() {
      this.$emit("banner", this.banner);
      this.openBannerConfig = false;
    },

    sendMeeting() {
      this.$emit("add-content", this.meet);
      this.openMeetingConfig = false;
    },

    sendScheduling() {
      this.$emit("add-content", this.calendar);
      this.openCalendarConfig = false;
    },

    selectStructure(html) {
      this.$emit("structure", html);
    },

    clickedOnFooterButton(key) {
      const footerActions = {
        banner: () => (this.openBannerConfig = true),
        meeting: () => (this.openMeetingConfig = true),
        calendar: () => (this.openCalendarConfig = true),
        visit_website: () => this.$emit("add-content", footersButtons[key]),
        button: () => (this.showButtonDialog = true),
      };

      footerActions[key]();
    },
  },

  beforeMount() {
    this.showStartModelTip = JSON.parse(
      localStorage.getItem("showStartModelTip")
    );
    if (this.showStartModelTip == null && this.emptyHTMLTemplate) {
      this.showStartModelTip = true;
      localStorage.setItem("showStartModelTip", "true");
    }
  },
};
</script>

<style scoped>
.col-style {
  width: 100% !important;
}

.template-details .v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px !important;
}

.template-details > .v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px !important;
}
</style>
